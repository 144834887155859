
.noData {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 160px;
    height: 160px;
    display: block;
  }

  p {
    display: block;
    color: #666666;
  }
}

.saleEcharts {
  // width: 200px;
  height: 300px;
  padding-right: 20px;
  box-sizing: border-box;
}