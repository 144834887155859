
    .eCommerce-container {
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
       
    }
    .tab-top {
        position: relative;
        .back-btn {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    .dataDesign{
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .title{
        padding-top: 10px;
        font-size: 16px;
        color: #1D2129 ;
    }
    
    .echarts{
        width: 100%;
        height: 320px;
        display: flex;
        box-sizing: border-box;
        .order_echarts{
            margin-left: 15px;
            flex: 1;
            height: 320px;
        }
        .sale_echarts{
            flex: 1;
            height: 320px;       
        }
    }
    .el-card{
        width: 100%;
        height: 100%;
    }
.number_echarts{
    width: 100%;
    margin-top: 15px;
    height: 320px;
}
.echarts_header{
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    color: #1D2129;
    .btn{
        display: flex;
    }

    .getDetial{
        color: rgb(2,167,240);
        margin-right: 10px;
        font-size: 14px;
        cursor: pointer;
    }
    .preview{
        color: rgb(2,167,240);
        font-size: 14px;
        cursor: pointer;
    }

}
// p{
//     display: inline-block !important;
// }
.caozuo{
       ::v-deep  p{
            display: inline-block !important;
           
        }
}
.preview-anser{
  width: 100%;
  display: flex;
  box-sizing: border-box;
  div{
    flex: 1;

  }
  .teacher_anser{
      margin-left: 20px;
    }
    ::v-deep .el-card__body {
        padding-bottom: 0;
    }
}
.el-card{
    border: none;
}
.no_answer{
    width: 100%;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;

}
.caozuotitle{
    background-color:#FAFAFA ;
    padding: 20px;
    box-sizing: border-box;
    margin: 10px 0;
    color: #333333; 
}
::v-deep .el-dialog__body{
    padding-top: 0px;
    padding-bottom: 60px !important;
}
